/* Plus Jakarta Display font */

@font-face{
  font-family: jakR;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Regular.woff2);
}

.jakR{
  font-family: jakR;
}

@font-face{
  font-family: jakM;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Medium.woff2);
}

.jakM{
  font-family: jakM;
}

@font-face{
  font-family: jakB;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Bold.woff2);
}

.jakB{
  font-family: jakB;
}



@font-face{
  font-family: Glook;
  src: url(./assets/fonts/Gloock-Regular.ttf);
}

.Glook{
  font-family: Glook;
}


@font-face{
  font-family: Pop;
  src: url(./assets/fonts/poppins/Poppins-Regular.ttf);
}

.Pop{
  font-family: Pop;
}


@font-face{
  font-family: PopL;
  src: url(./assets/fonts/poppins/Poppins-Light.ttf);
}

.PopL{
  font-family: PopL;
}

@font-face{
  font-family: PopM;
  src: url(./assets/fonts/poppins/Poppins-Medium.ttf);
}

.PopM{
  font-family: PopM;
}

@font-face{
  font-family: PopT;
  src: url(./assets/fonts/poppins/Poppins-Thin.ttf);
}

.PopT{
  font-family: PopT;
}

@font-face{
  font-family: PopB;
  src: url(./assets/fonts/poppins/Poppins-Bold.ttf);
}

.PopB{
  font-family: PopB;
}

@font-face{
  font-family: PopK;
  src: url(./assets/fonts/poppins/Poppins-Black.ttf);
}

.PopK{
  font-family: PopK;
}


body {
  background-color: #121212;
}

/* chekbox */
.css-sc27ck {
  border: 1px solid #5f3d98 !important;
}

.css-iwhvoh {
  border: 2px solid #3d3d40 !important;
}

.css-b0fd6v  {
  border: 0px !important;

}